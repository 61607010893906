<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="sign__content">
          <!-- authorization form -->
          <form
            v-on:submit.prevent="onLogin()"
            class="sign__form vld-parent"
            ref="formContainer"
          >
            <a href="index.php" class="sign__logo">
              <img src="assets/img/SudaniWhiteLogo-NoShadow.png" alt="" />
            </a>
            <div class="sign__group">
              <span class="sign__delimiter">رقم الهاتف </span>
              <input
                type="phone"
                v-model="msisdn"
                class="sign__input "
                placeholder="2491XXXXXXXX"
              />
            </div>
            <div class="sign__group">
              <button class="sign__btn">
                <span v-if="loading" class="px-1 arabickufi"
                  >جاري تسجيل الدخول</span
                >
                &nbsp;
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                ></div>
                <span v-else class="arabickufi">تسجيل الدخول</span>
              </button>
              <!-- <button class="sign__btn" type="submit">تسجيل دخول</button> -->
              <span class="sign__delimiter"><h6></h6></span>
            </div>
          </form>
          <!-- end authorization form -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/Api/http-common";
import { useToast } from "vue-toastification";
export default {
  name: "Logins",
  data() {
    return {
      msisdn: "",
      loading: false,
    };
  },
  mounted() {
    if (
      this.$cookie.isCookieAvailable("msisdn") &&
      this.$cookie.isCookieAvailable("status")
    ) {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    async onLogin() {
      const toast = useToast();
      if(this.msisdn.startsWith(9)) {
          toast.error("رقم الهاتف ليس سوداني", {
            timeout: 2000,
          });
      } else {
        if (this.msisdn != "") {
        const str = this.msisdn;
        if (str.substr(0, 1) === "0") {
          let number = str.substr(1);
          if (number.startsWith(1)) {
            this.phone = number;
          } else {
            toast.error("رقم الهاتف ليس سوداني", {
              timeout: 3000,
            });
          }
        } else if (str.substr(0, 1) === "2" && str.length === 12) {
          let number = str.substr(3);
          if (number.startsWith(1)) {
            this.phone = number;
          } else {
            toast.error("رقم الهاتف ليس سوداني", {
              timeout: 3000,
            });
          }
        } else if (str.substr(0, 1) === "1" && str.length === 9) {
          let number = str.substr();
          if (number.startsWith(1)) {
            this.phone = number;
          } else {
            toast.error("رقم الهاتف ليس سوداني", {
              timeout: 3000,
            });
          }
        } else if (str.length != 9) {
          toast.error("رقم الهاتف يجب ان يتكون من 10 ارقام", {
            timeout: 3000,
          });
        }
        // var phoneno = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/;
        // if (this.num.match(phoneno)) {
          if (this.phone.startsWith(1)) {
            // let phone = this.num.substr(1);
            this.loading = true;
            await HTTP.post(
              `CheckLogin.php?username=249` +
                this.phone +
                "&serviceId=2222&operatorID=2"
            )
              .then((res) => {
                if (res.data.status === 1 && res.data.MINTUES > 0) {
                  this.$cookie.setCookie("msisdn", "249" + this.phone, {
                    expire: 60 * res.data.MINTUES,
                  });
                  this.$cookie.setCookie("status", res.data.status, {
                    expire: 60 * res.data.MINTUES,
                  });
                  this.$cookie.setCookie("remming_minutes", res.data.MINTUES, {
                    expire: 60 * res.data.MINTUES,
                  });
                  toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", {
                    timeout: 2000,
                  });
                  setTimeout(() => this.$router.push({ path: "/" }), 2500);
                } else if (res.data.status === 1 && res.data.MINTUES < 0) {
                  toast.success("تم تسجيل الدخول", { timeout: 2500 });
                  this.$cookie.setCookie("msisdn", "249" + this.phone, {
                    expire: 60 * 120,
                  });
                  this.$cookie.setCookie("status", res.data.status, {
                    expire: 60 * 120,
                  });
                  this.$cookie.setCookie("remming_minutes", res.data.MINTUES, {
                    expire: 60 * 120,
                  });
                } else if (res.data.status === 0) {
                  toast.warning("عفوا انت لست مشترك في منصة نشوف", {
                    timeout: 2500,
                  });
                  setTimeout(() => window.location.href="http://sudanipay.com/subscription/nashoof-sd/nashoof-sd", 2500);
                } else {
                  toast.info("عفوا انت لست مشترك في منصة نشوف", {
                    timeout: 2500,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            toast.info("رقم الهاتف ليس سوداني", { timeout: 2500 });
          }
        // } else {
        //   toast.info("رقم الهاتف يجب ان يتكون من 10 رقم ويبدا بي 01 ", {
        //     timeout: 2500,
        //   });
        // }
      } else {
        toast.info("الرجال ادخال رقم الهاتف", { timeout: 2500 });
      }
      }
      // const str = this.msisdn;
      
    },
  },
};
</script>

<style scoped>
.button {
  position: relative;
  padding: 8px 16px;
  background: #009579;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}

.button:active {
  background: #007a63;
}

.button__text {
  font: bold 20px "Quicksand", san-serif;
  color: #ffffff;
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>